type ButtonProps = {
  buttonClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  text: string;
  disabled?: boolean;
};

export default function Button({
  buttonClick,
  text,
  disabled = false,
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      className="button-click"
      onClick={(evt) => {
        evt.preventDefault();
        buttonClick(evt);
      }}
    >
      {text}
    </button>
  );
}
