import React from "react";
import ModulContainer from "./ModulContainer";
import ButtonClose from "../buttons/ButtonClose";

type ModuleQuoteProps = {
  text: string;
  clickOverly: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ModuleText({ text, clickOverly }: ModuleQuoteProps) {
  return (
    <ModulContainer clickOverly={() => clickOverly(false)}>
      <p className="module-text">
        {text}
        <ButtonClose funcClose={() => clickOverly(false)} />
      </p>
    </ModulContainer>
  );
}
