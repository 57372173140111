import ModulContainer from "./ModulContainer";

type ModulConfirmationProps = {
  text?: string;
  confirm?: () => void;
  close: () => void;
};

export default function ModulConfirmation({
  text,
  confirm,
  close,
}: ModulConfirmationProps) {
  const confirmYes = () => {
    close();
    confirm && confirm();
  };

  return (
    <ModulContainer
      clickOverly={() => {
        close();
      }}
    >
      <div className="modul-confirmation">
        <p className="modul-confirmation__title">{text}</p>
        <button
          onClick={() => confirmYes()}
          className="modul-confirmation__buttonYes modul-confirmation__button"
        >
          Да
        </button>
        <button
          onClick={() => {
            close();
          }}
          className="modul-confirmation__buttonNo modul-confirmation__button"
        >
          Нет
        </button>
      </div>
    </ModulContainer>
  );
}
