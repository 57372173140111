const getTimeLocal = (date: string): string | undefined => {
  if (!date) {
    return;
  }

  const timeLocal = new Date(date);

  let formatter = new Intl.DateTimeFormat("ru", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return formatter.format(timeLocal);
};

const cutTime = (time: string): string => {
  const text = getTimeLocal(time);
  if (text) {
    const textDate = text.replace(/,/, " ");
    return textDate;
  }
  return "";
};

export { getTimeLocal, cutTime };
