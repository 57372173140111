import { OrdersRes } from "../types/typeOrders";
import { URL_SERVER } from "../utils/Constants";
import { cutTime } from "../utils/utils";
import ListOrders from "./ListOrders";
import Button from "./buttons/Button";

type ListCardOrderProps = {
  orders: OrdersRes[] | undefined;
  click: (id: string) => void;
  clickRead?: (id: string) => void;
  textButton: string;
  textReadButton?: string;
  arr: boolean | string;
  errorText: string;
};

export default function ListCardOrder({
  orders,
  click,
  textButton,
  textReadButton,
  clickRead,
  arr,
  errorText,
}: ListCardOrderProps) {
  return (
    <ul className="orders__list">
      {orders &&
        orders.map((order) => (
          <li className="orders__order" key={order._id}>
            <div className="orders__box-header">
              <h3 className="orders__name">
                <span className="orders__name-phone">Имя:</span>
                {order.name}
              </h3>
              <span className="orders__date">{cutTime(order.dateCreate)}</span>
            </div>
            <span className="orders__phone">
              <span className="orders__name-phone">Teлефон: </span>
              {order.phone}
            </span>
            <ul className="bag-list">
              {order.products.map((elem, i) => (
                <ListOrders
                  key={elem._id}
                  product={elem}
                  url={URL_SERVER}
                ></ListOrders>
              ))}
            </ul>
            <div className="orders__box-button">
              {textReadButton && !order.read && (
                <Button
                  buttonClick={() => {
                    clickRead && clickRead(order._id);
                  }}
                  text={textReadButton}
                />
              )}
              <Button buttonClick={() => click(order._id)} text={textButton} />
            </div>
          </li>
        ))}
    </ul>
  );
}
