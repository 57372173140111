import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CardProduct } from '../../types/typeProduts';

interface ProductState {
  arrProduct: CardProduct[];
  sortProduct: CardProduct[];
}

const initialState: ProductState = {
  arrProduct: [],
  sortProduct: [],
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setSearchProduct(state, action: PayloadAction<CardProduct[]>) {
      state.sortProduct = action.payload;
    },
    setArrProduct(state, action: PayloadAction<CardProduct[]>) {
      state.arrProduct = action.payload;
      state.sortProduct = action.payload;
    },
  },
});

export const selectProduct = (state: RootState) => state.product;
export const { setSearchProduct, setArrProduct } = productSlice.actions;
export default productSlice.reducer;
