type Сoordinates = {
  top: number | undefined;
  left: number | undefined;
};

const geеСoordinatesClue = (
  refClue: React.RefObject<HTMLSpanElement>,
  parentClue: string
): Сoordinates => {
  let top;
  let left;

  if (refClue && refClue.current) {
    const clue = refClue.current?.getBoundingClientRect();

    const parent = refClue.current
      ?.closest(parentClue)
      ?.getBoundingClientRect();

    const widthWindow = document.documentElement.clientWidth;

    if (parent) {
      if (parent.top > clue.height) {
        top = -clue.height + 17;
      } else {
        top = parent.height + 5;
      }

      if (widthWindow - parent.left < clue.width) {
        left = -clue.width - 5;
      } else {
        left = parent.width - 35;
      }
    }

    return { top, left };
  }

  return { top, left };
};

export { geеСoordinatesClue };
