import { useDispatch, useSelector } from "react-redux";
import {
  isPopUpWindow,
  selectPopUpWindow,
} from "../redax/slices/popUpWindowSlice";
import { useRef } from "react";
import ButtonClose from "./buttons/ButtonClose";

type PopUpWindowProps = {
  text: string;
};

export default function PopUpWindow({ text }: PopUpWindowProps) {
  const dispatch = useDispatch();
  const windowRef = useRef<HTMLDivElement>(null);
  const { stateWindow } = useSelector(selectPopUpWindow);
  const widthWindow = window.innerWidth;
  const widthElem = windowRef.current?.offsetWidth;
  const rightIndent = widthElem && (widthWindow - widthElem) / 2;

  return (
    <div
      style={{ right: rightIndent }}
      ref={windowRef}
      className={`pop-up-window pop-up-window_color_red ${
        stateWindow && "pop-up-window_state_on"
      }`}
    >
      <h1 className="pop-up-window__text">{text}</h1>
      <ButtonClose funcClose={() => dispatch(isPopUpWindow(false))} />
    </div>
  );
}
