import React, { useEffect, useState } from "react";
import { product } from "../utils/ProductApi";
import { useSelector } from "react-redux";
import { selectAuth } from "../redax/slices/authSlice";
import { CardProduct, Check, ProducSet } from "../types/typeProduts";
import { useNavigate } from "react-router-dom";

import {
  FotoAdd,
  ButtonsNavigation,
  Button,
  ErrorBox,
  CheckBox,
  ModulConfirmation,
  ModulePreloader,
  ErrorSpan,
} from "../components/index";
import ButtonReset from "../components/buttons/ButtonReset";
import { Input } from "../types/tupes";

type PromisPatch =
  | {
      res: CardProduct;
      status: boolean;
    }
  | { res: string; status: boolean };

type SendChangeValue = {
  id: string;
  token: string;
  value: string;
};

type ParamsAddFoto = {
  fileSend: FormData | undefined;
  token: string | null;
  id: string | null;
};

type Confirmation = {
  func?: () => void;
  state: boolean;
  text?: string;
  name?: string | undefined;
};

export default function FormEditProduct() {
  const navigate = useNavigate();
  const { token } = useSelector(selectAuth);
  const [values, setValues] = useState<ProducSet>();
  const [valuesNew, setValuesNew] = useState<ProducSet>();
  const [buttonAdd, isButtonAdd] = useState(true);
  const [buttonsSend, isButtonsSend] = useState(false);
  const [errText, isErrText] = useState("");
  const [file, setFile] = useState<string | ArrayBuffer>();
  const [fileResult, setFileResult] = useState<string | ArrayBuffer>();
  const [fileSend, setFileSend] = useState<FormData>();
  const [preloaderFoto, isPreloaderFoto] = useState(false);
  const [preloaderInput, isPreloaderInput] = useState(false);
  const [arrChecked, isArrChecked] = useState<Check[]>();
  const [arrCheckedOld, isArrCheckedOld] = useState<boolean[]>();
  const [productRes, isProductRes] = useState(true);
  const [preloaderProduct, isPreloaderProduct] = useState(true);
  const [confirmation, isConfirmation] = useState<Confirmation>({
    state: false,
    name: undefined,
  });
  const [fileLoading, isFileLoading] = useState(false);
  const [inputValidity, isInputValidity] = useState<Input>();

  const id = localStorage.getItem("id-product");

  const getProduct = (token: string, id: string): void => {
    isPreloaderProduct(true);
    product
      .getIdProduct(token, id)
      .then((res: CardProduct) => {
        setFile(res.foto);
        setFileResult(res.foto);
        setValues(res);
        setValuesNew(res);
        isArrCheckedOld(res.gender.map((elem) => elem.checked));
        isArrChecked(res.gender);
      })
      .catch((err) => {
        isErrText(err.message);
        isProductRes(false);
      })
      .finally(() => isPreloaderProduct(false));
  };

  useEffect(() => {
    if (token && id) {
      getProduct(token, id);
    } else {
      navigate("/product");
    }

    return () => {
      localStorage.removeItem("id-product");
    };
  }, []);

  const changeValues = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;
    const validity = evt.target.checkValidity();
    const regex = /[^\s]+/;
    const result = regex.test(value);
    if (name === "price" && value.length > 6) {
      return;
    }
    if (validity && result) {
      isInputValidity({ ...inputValidity, [name]: true });
    } else {
      isInputValidity({ ...inputValidity, [name]: false });
    }

    if (valuesNew) {
      setValuesNew({ ...valuesNew, [name]: value });
    }
  };

  const showErrChangeFoto = (text: string): void => {
    isPreloaderFoto(false);
    isErrText(text);
    setTimeout(() => isErrText(""), 1200);
  };

  const addfoto = ({ fileSend, token, id }: ParamsAddFoto): void => {
    if (fileSend && token && id) {
      product
        .addFoto(fileSend, token, id)
        .then((res: string) => {
          setFileResult(res);
          isButtonsSend(false);
          isButtonAdd(true);
        })
        .catch((err) => {
          isErrText(`на сервере произошла ошибка : "${err.message}"`);
          setTimeout(() => isErrText(""), 1200);
        })
        .finally(() => {
          isPreloaderFoto(false);
        });
    }
  };

  function sendChangeFoto({ fileSend, token, id }: ParamsAddFoto): void {
    isPreloaderFoto(true);
    if (!fileSend) {
      showErrChangeFoto("выберите файл");
      return;
    }
    if (fileSend && token && id) {
      product
        .deleteFotoProduct({ token, id })
        .then((res) => {
          addfoto({ fileSend, token, id });
        })
        .catch((err) => {
          isErrText(`на сервере произошла ошибка : "${err.message}"`);
          setTimeout(() => isErrText(""), 1200);
          console.log(err);
        })
        .finally(() => {
          isPreloaderFoto(false);
        });
    } else {
      showErrChangeFoto("выберите файл");
    }
  }

  const sendAddedFoto = ({ fileSend, token, id }: ParamsAddFoto): void => {
    isPreloaderFoto(true);
    if (!fileSend) {
      showErrChangeFoto("выберите файл");
      return;
    }
    if (fileSend && token && id) {
      addfoto({ fileSend, token, id });
    }
  };

  const sendPrice = ({
    id,
    token,
    value,
  }: SendChangeValue): Promise<PromisPatch> => {
    return product
      .patchPrice({ id, token, price: value })
      .then((res: CardProduct) => {
        return { res, status: true };
      })
      .catch((err: { message: string }) => {
        console.log(err.message);
        return { res: err.message, status: false };
      });
  };

  const sendDiscription = ({
    id,
    token,
    value,
  }: SendChangeValue): Promise<PromisPatch> => {
    return product
      .patchDiscription({ id, token, discription: value })
      .then((res: CardProduct) => {
        return { res, status: true };
      })
      .catch((err: { message: string }) => {
        return { res: err.message, status: false };
      });
  };

  const sendTitle = ({
    id,
    token,
    value,
  }: SendChangeValue): Promise<PromisPatch> => {
    return product
      .patchTitle({ id, token, title: value })
      .then((res: CardProduct) => {
        return { res, status: true };
      })
      .catch((err: { message: string }) => {
        console.log(err.message);
        return { res: err.message, status: false };
      });
  };

  const sendChange = async (
    id: string | null,
    token: string | null,
    value: string | undefined,
    func: ({ id, token, value }: SendChangeValue) => Promise<PromisPatch>,
    name: string
  ): Promise<any> => {
    if (!value) {
      isErrText("Все поля должны быть заполнены");
      setTimeout(() => isErrText(""), 1500);
      return;
    }
    if (id && token) {
      isPreloaderInput(true);
      const data = await func({ id, token, value });
      if (data.status === false) {
        isPreloaderInput(false);
        isErrText(`При редактирование произошла ошибка: ${data.res}`);
        setTimeout(() => isErrText(""), 1500);
      } else {
        typeof data.res === "object" && setValues(data.res);
        isInputValidity({ ...inputValidity, [name]: undefined });
        isPreloaderInput(false);
      }
    } else {
      isPreloaderInput(false);
      isErrText("При редактирование произошла ошибка");
      setTimeout(() => isErrText(""), 1500);
    }
  };

  const findChecked = (arrCheck: Check[]): Check | undefined => {
    return arrCheck.find((elem) => elem.checked === true);
  };

  const sendGender = (
    id: string | null,
    token: string | null,
    gender: Check[] | undefined
  ) => {
    if (id && gender && findChecked(gender) && token) {
      isPreloaderInput(true);
      product
        .patchGender({ id, token, gender })
        .then((res: CardProduct) => {
          isArrCheckedOld(res.gender.map((elem) => elem.checked));
        })
        .catch((err: { message: string }) => {
          console.log(err.message);
          isErrText("При редактирование произошла ошибка");
          setTimeout(() => isErrText(""), 1500);
        })
        .finally(() => isPreloaderInput(false));
    } else {
      isErrText("Выбирете чекбокс");
      setTimeout(() => isErrText(""), 1500);
    }
  };

  const resetValue = (
    value: string | undefined,
    valueNew: string | undefined,
    name: string,
    set: React.Dispatch<React.SetStateAction<ProducSet | undefined>>
  ): void => {
    isInputValidity({ ...inputValidity, [name]: undefined });
    if (value && valueNew) {
      if (value === valueNew) {
        return;
      } else {
        valuesNew && set({ ...valuesNew, [name]: value });
      }
    } else {
      valuesNew && set({ ...valuesNew, [name]: value });
    }
  };

  const resetGender = (arr: boolean[], arrNew: Check[]) => {
    const arrCheckNew = arrNew.map((elem) => elem.checked);
    if (arr.toString() === arrCheckNew.toString()) {
      return;
    } else {
      const newArr = arrNew.map((elem, i) => {
        return { checked: arr[i], label: elem.label, value: elem.value };
      });
      values?.gender && isArrChecked(newArr);
    }
  };

  const deleteFoto = (token: string | null, id: string | null) => {
    isPreloaderFoto(true);
    if (id && token) {
      product
        .deleteFotoProduct({ token, id })
        .then((res) => {
          //console.log(res);
          product
            .resetFoto(token, id)
            .then((res) => {
              //console.log(res);
              setFileResult("");
              setFile("");
            })
            .catch((err) => {
              //console.log(err);
              setFileResult("");
              showErrChangeFoto(
                `На сервере произошла ошибка : "${err.message}"`
              );
            })
            .finally(() => {
              isPreloaderFoto(false);
            });
        })
        .catch((err) => {
          showErrChangeFoto(
            `При удалении фото произошла ошибка : "${err.message}"`
          );
          //console.log(err);
        });
    }
  };

  const orderInput = (
    valueNew: string | undefined,
    valueOld: string | undefined
  ): boolean => {
    if (valueNew !== valueOld && valueNew?.length && valueNew.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const orderCheckBox = (
    checkOld: boolean[] | undefined,
    checkNew: Check[] | undefined
  ): boolean => {
    if ((checkOld || checkNew) === undefined) {
      return true;
    }
    if (compareCheckBox(checkOld, checkNew)) {
      return true;
    } else if (checkNew?.find((elem) => elem.checked === true)) {
      return false;
    } else {
      return true;
    }
  };

  const compareCheckBox = (
    checkOld: boolean[] | undefined,
    checkNew: Check[] | undefined
  ): boolean => {
    return checkOld?.toString() ===
      checkNew?.map((elem) => elem.checked).toString()
      ? true
      : false;
  };

  return (
    <div className="edit-product">
      <div className="edit-product__box-nav-button">
        <ButtonsNavigation page="/product" text="Назад" />
        <ButtonsNavigation page="/" text="Главная" />
      </div>
      <h2 className="edit-product__title">Редактирование</h2>
      {productRes ? (
        <>
          <FotoAdd
            fileLoading={fileLoading}
            isFileLoading={isFileLoading}
            buttonAdd={buttonAdd}
            isButtonAdd={isButtonAdd}
            isButtonsSend={isButtonsSend}
            isErrText={isErrText}
            file={file}
            setFile={setFile}
            setFileSend={setFileSend}
            textButton={file ? "Изменить фото" : "Добавить фото"}
          >
            {file ? (
              <Button
                text="Удалить"
                buttonClick={() => {
                  isConfirmation({
                    func: () => deleteFoto(token, id),
                    state: true,
                    text: "Удалить?",
                  });
                }}
              />
            ) : undefined}
          </FotoAdd>
          {buttonsSend && (
            <div className="edit-product__box-send-button">
              <Button
                buttonClick={
                  fileResult
                    ? () => sendChangeFoto({ fileSend, token, id })
                    : () => sendAddedFoto({ fileSend, token, id })
                }
                text="Загрузить"
              />
              <Button
                buttonClick={() => {
                  if (token && id) {
                    getProduct(token, id);
                    isButtonsSend(false);
                    isButtonAdd(true);
                    isFileLoading(false);
                  } else {
                    navigate("/product");
                  }
                }}
                text="Отменить"
              />
            </div>
          )}
          <ErrorBox>
            <ErrorSpan text={errText} />
          </ErrorBox>
          <form className="form">
            <div className="form__input-box">
              <label className="form__label">Название:</label>
              <input
                required
                minLength={1}
                maxLength={50}
                className={`input form__input ${
                  inputValidity?.title
                    ? "input_state_valid"
                    : inputValidity?.title === undefined
                    ? ""
                    : "input_state_invalid-custom"
                }`}
                value={valuesNew?.title || ""}
                name="title"
                onChange={(evt) => changeValues(evt)}
              ></input>
              <div className="form__box-batton">
                <Button
                  disabled={
                    inputValidity?.title
                      ? orderInput(valuesNew?.title, values?.title)
                      : true
                  }
                  buttonClick={() => {
                    isConfirmation({
                      func: () =>
                        sendChange(
                          id,
                          token,
                          valuesNew?.title,
                          sendTitle,
                          "title"
                        ),
                      state: true,
                      text: "Изменить?",
                      name: "title",
                    });
                  }}
                  text="Изменить"
                />
              </div>
              <ButtonReset
                disabled={valuesNew?.title === values?.title}
                click={() =>
                  resetValue(
                    values?.title,
                    valuesNew?.title,
                    "title",
                    setValuesNew
                  )
                }
              />
            </div>
            <div className="form__input-box">
              <label className="form__label">Описание:</label>
              <textarea
                required
                minLength={1}
                maxLength={1000}
                className={`input form__input ${
                  inputValidity?.discription
                    ? "input_state_valid"
                    : inputValidity?.discription === undefined
                    ? ""
                    : "input_state_invalid-custom"
                }`}
                value={valuesNew?.discription || ""}
                name="discription"
                onChange={(evt) => changeValues(evt)}
                rows={3}
              ></textarea>
              <div className="form__box-batton">
                <Button
                  disabled={
                    inputValidity?.discription
                      ? orderInput(valuesNew?.discription, values?.discription)
                      : true
                  }
                  buttonClick={() => {
                    isInputValidity({
                      ...inputValidity,
                      discription: undefined,
                    });
                    isConfirmation({
                      func: () =>
                        sendChange(
                          id,
                          token,
                          valuesNew?.discription,
                          sendDiscription,
                          "discription"
                        ),
                      state: true,
                      text: "Изменить?",
                      name: "discription",
                    });
                  }}
                  text="Изменить"
                />
              </div>
              <ButtonReset
                disabled={valuesNew?.discription === values?.discription}
                click={() =>
                  resetValue(
                    values?.discription,
                    valuesNew?.discription,
                    "discription",
                    setValuesNew
                  )
                }
              />
            </div>
            <div className="form__input-box">
              <label className="form__label">Цена:</label>
              <input
                required
                type="number"
                min={1}
                max={999999}
                className={`input form__input ${
                  inputValidity?.price
                    ? "input_state_valid"
                    : inputValidity?.price === undefined
                    ? ""
                    : "input_state_invalid-custom"
                }`}
                value={valuesNew?.price || ""}
                name="price"
                onChange={(evt) => changeValues(evt)}
              ></input>
              <div className="form__box-batton">
                <Button
                  disabled={orderInput(valuesNew?.price, values?.price)}
                  buttonClick={(evt) => {
                    isInputValidity({ ...inputValidity, price: undefined });

                    isConfirmation({
                      func: () =>
                        sendChange(
                          id,
                          token,
                          valuesNew?.price,
                          sendPrice,
                          "price"
                        ),
                      state: true,
                      text: "Изменить?",
                      name: "price",
                    });
                  }}
                  text="Изменить"
                />
              </div>
              <ButtonReset
                disabled={valuesNew?.price === values?.price}
                click={() =>
                  resetValue(
                    values?.price,
                    valuesNew?.price,
                    "price",
                    setValuesNew
                  )
                }
              />
            </div>

            <div className="form__check-box">
              {arrChecked && (
                <CheckBox
                  arrChecked={arrChecked}
                  isArrChecked={isArrChecked}
                  isValid={
                    arrChecked?.find((elem) => elem.checked === true) ===
                    undefined
                  }
                />
              )}
              <div className="checkbox">
                <Button
                  disabled={orderCheckBox(arrCheckedOld, arrChecked)}
                  buttonClick={(evt) => {
                    evt.preventDefault();
                    isConfirmation({
                      func: () => sendGender(id, token, arrChecked),
                      state: true,
                      text: "Изменить?",
                    });
                  }}
                  text="Изменить"
                />
                <ButtonReset
                  disabled={compareCheckBox(arrCheckedOld, arrChecked)}
                  click={() => {
                    if (arrCheckedOld && arrChecked) {
                      resetGender(arrCheckedOld, arrChecked);
                    }
                  }}
                />
              </div>
            </div>
          </form>
        </>
      ) : (
        <ErrorBox>
          <ErrorSpan
            text={`При загрузке данных произошла ошибка (${errText}), попробуйте перезагрузить страницу`}
          />
        </ErrorBox>
      )}
      {preloaderFoto && <ModulePreloader text="Загрузка" />}
      {preloaderInput && <ModulePreloader text="Изменение" />}
      {preloaderProduct && <ModulePreloader text="Данные товара загружаются" />}
      {confirmation.state && (
        <ModulConfirmation
          text={confirmation.text}
          confirm={confirmation.func}
          close={() => {
            inputValidity &&
              confirmation.name &&
              isInputValidity({ ...inputValidity, [confirmation.name]: true });
            isConfirmation({ ...confirmation, state: false });
          }}
        />
      )}
    </div>
  );
}
