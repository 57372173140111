import { Check } from "../types/typeProduts";

type CheckProps = {
  arrChecked: Check[];
  isArrChecked: React.Dispatch<React.SetStateAction<Check[] | undefined>>;
  isValid: boolean;
};

export default function CheckBox({
  arrChecked,
  isArrChecked,
  isValid,
}: CheckProps) {
  return (
    <div className="checkbox">
      {arrChecked.map((elem, i) => (
        <div key={i} className="checkbox__container">
          <span>{elem.label}</span>
          <button
            type="button"
            onClick={() => {
              const newCheck = arrChecked.map((elem, index) => {
                if (i === index) {
                  elem.checked = !elem.checked;
                  return elem;
                } else {
                  return elem;
                }
              });
              isArrChecked(newCheck);
            }}
            className={`checkbox__padding ${
              isValid ? "checkbox_active_invalid" : ""
            }`}
          >
            <div
              className={`checkbox__change ${
                elem.checked && "checkbox__change_checked"
              }`}
            ></div>
          </button>
        </div>
      ))}
    </div>
  );
}
