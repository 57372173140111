import { URL_SERVER } from "./Constants";

export type OrderApi = {
  baseUrl: string;
};

class Order {
  readonly baseUrl: string;
  constructor({ baseUrl }: OrderApi) {
    this.baseUrl = baseUrl;
  }

  public getAllOrder(token: string) {
    return fetch(this.baseUrl, {
      method: "get",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token") || token}`,
        "content-type": "application/json",
      },
    }).then(this._checkResponse);
  }

  public getNotReadOrder(token: string) {
    return fetch(`${this.baseUrl}/not-read`, {
      method: "get",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token") || token}`,
        "content-type": "application/json",
      },
    }).then(this._checkResponse);
  }

  public getArchiveOrder(token: string) {
    return fetch(`${this.baseUrl}/archive`, {
      method: "get",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token") || token}`,
        "content-type": "application/json",
      },
    }).then(this._checkResponse);
  }

  public deleteOrder({ id, token }: { id: string; token: string | null }) {
    return fetch(`${this.baseUrl}/${id}`, {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token") || token}`,
      },
    }).then(this._checkResponse);
  }

  public patchArchivOrder(token: string, id: string) {
    return fetch(`${this.baseUrl}/archive/${id}`, {
      method: "PATCH",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token") || token}`,
        "content-type": "application/json",
      },
      body: JSON.stringify({
        archive: true,
      }),
    }).then(this._checkResponse);
  }

  public patchReadOrder(token: string, id: string) {
    return fetch(`${this.baseUrl}/read/${id}`, {
      method: "PATCH",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token") || token}`,
        "content-type": "application/json",
      },
    }).then(this._checkResponse);
  }

  private _checkResponse = (res: Response) => {
    if (res.ok) {
      return res.json();
    }
    return res.text().then((err) => Promise.reject(JSON.parse(err)));
  };
}

const order = new Order({
  baseUrl: `${URL_SERVER}/order`,
});

export { order };
