type ButtonResetProps = {
  click: () => void;
  disabled?: boolean;
};

export default function ButtonReset({
  click,
  disabled = false,
}: ButtonResetProps) {
  return (
    <button
      disabled={disabled}
      tabIndex={0}
      onClick={click}
      className="edit-product__reset-button"
      title="сбросить изменения"
    ></button>
  );
}
