import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import FormAuthorization from './pages/FormAuthorization';
import FormRegistration from './pages/FormRegistration';
import { useSelector } from 'react-redux';
import { selectAuth } from './redax/slices/authSlice';
import ListProducte from './pages/ListProducte';
import FormAddProducte from './pages/FormAddProducte';
import FormAddFotoProduct from './pages/FormAddFotoProduct';
import FormEditProduct from './pages/FormEditProduct';
import Orders from './pages/Orders';
import OrdersArchive from './pages/OrdersArchive';

function App() {
  const { token } = useSelector(selectAuth);

  return (
    <Routes>
      <Route
        path="/"
        element={token ? <Home /> : <FormAuthorization />}
      ></Route>
      <Route path="/registration" element={<FormRegistration />}></Route>
      <Route path="/auth" element={<FormAuthorization />}></Route>
      <Route
        path="/product"
        element={token ? <ListProducte /> : <FormAuthorization />}
      ></Route>
      <Route
        path="/add-product"
        element={token ? <FormAddProducte /> : <FormAuthorization />}
      ></Route>
      <Route
        path="/add-foto-product"
        element={token ? <FormAddFotoProduct /> : <FormAuthorization />}
      ></Route>
      <Route
        path="/edit-product"
        element={token ? <FormEditProduct /> : <FormAuthorization />}
      ></Route>
      <Route
        path="/order"
        element={token ? <Orders /> : <FormAuthorization />}
      ></Route>
      <Route
        path="/order-archive"
        element={token ? <OrdersArchive /> : <FormAuthorization />}
      ></Route>
    </Routes>
  );
}

export default App;
