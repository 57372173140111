import { useNavigate } from "react-router-dom";
import { order } from "../utils/OrderApi";
import { selectAuth } from "../redax/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import webSocket from "../utils/socket";
import { isPopUpWindow } from "../redax/slices/popUpWindowSlice";

import SearchLitle from "./SearchLitle";
import PopUpWindow from "./PopUpWindowErr";

type Menu = {
  title: string;
  router: string;
  newOrders?: number;
};

export default function MenuNavigate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector(selectAuth);
  const [newOrders, isNewOrders] = useState(0);
  const [search, isSearch] = useState(true);
  const [popupText, isPopupText] = useState("");

  const menu: Menu[] = [
    { title: "Посмотреть товар", router: "/product" },
    { title: "Добавить товар", router: "/add-product" },
    { title: "Заказы", router: "/order", newOrders },
    { title: "Архив заказов", router: "/order-archive" },
  ];

  useEffect(() => {
    token &&
      order
        .getNotReadOrder(token)
        .then((res: number) => {
          isNewOrders(res);
        })
        .catch((err) => {
          isPopupText(
            `При получении колличества заказов произошла ошибка: ${err}`
          );
          dispatch(isPopUpWindow(true));
          setTimeout(() => {
            isPopupText("");
            dispatch(isPopUpWindow(false));
          }, 3000);
        })
        .finally(() => isSearch(false));

    webSocket.emit("ROOM:JOIN");

    webSocket.on(
      "ROOM:GET_NOT_READ_ORDER",
      (orderNum: { answer: number; status: boolean }) => {
        orderNum.status && isNewOrders(orderNum.answer);
      }
    );
  }, []);

  const onKeydown = (evt: KeyboardEvent, router: string) => {
    if (evt.code === "Enter") {
      console.log(router);
      navigate(router);
    }
  };

  return (
    <ul className="menu-navigate">
      {menu.map((elem, i) => (
        <li
          onFocus={(evt) => {
            evt.target.addEventListener(
              "keydown",
              (evt: KeyboardEvent) => onKeydown(evt, elem.router),
              { once: true }
            );
          }}
          tabIndex={0}
          key={i + elem.router}
          className={`menu-navigate__title ${
            elem.newOrders != undefined
              ? elem.newOrders >= 0 && "menu-navigate__title_space_between"
              : ""
          }`}
          onClick={() => {
            navigate(elem.router);
          }}
        >
          {elem.title}
          {elem.newOrders != undefined && (
            <div className={`${elem.newOrders && "menu-navigate__counter"}`}>
              {elem.newOrders === 0 ? (
                search ? (
                  <SearchLitle />
                ) : (
                  "0"
                )
              ) : (
                elem.newOrders
              )}
            </div>
          )}
        </li>
      ))}
      <PopUpWindow text={popupText} />
    </ul>
  );
}
