import React, { useRef, useState } from "react";
import { product } from "../utils/ProductApi";
import { selectAuth } from "../redax/slices/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Check } from "../types/typeProduts";

import {
  CheckBox,
  ModulePreloader,
  Form,
  ButtonsNavigation,
} from "../components/index";
import { Input } from "../types/tupes";

export type objValueInput = {
  [key: string]: string | string[];
  gender: string[];
  discription: string;
  price: string;
  title: string;
};

export default function FormAddProducte() {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [arrValidation, isArrValidation] = useState("");
  const [preloader, isPreloader] = useState(false);
  const arr = [
    { label: "М", value: "Для мужчин", checked: false },
    { label: "Ж", value: "Для женщин", checked: false },
  ];

  const [arrChecked, isArrChecked] = useState<Check[] | undefined>(arr);
  const [invalid, isInvalid] = useState<boolean>(false);
  const [inputValidity, isInputValidity] = useState<Input>({
    title: false,
    price: false,
    discription: false,
  });

  const { token } = useSelector(selectAuth);

  const collectValues = (
    evt: React.FormEvent<HTMLFormElement>
  ): objValueInput => {
    const elem = (evt.target as HTMLFormElement).elements;
    const objValue: objValueInput = {
      gender: [],
      discription: "",
      price: "",
      title: "",
    };
    for (let index = 0; index < elem.length; index++) {
      const elemCurrent = elem[index] as HTMLFormElement;
      const elemName = elemCurrent.name;
      if (elemName.length === 0) {
      } else {
        objValue[elemName] = elemCurrent.value;
      }
    }

    return objValue;
  };

  const validetionTextinput = (arrayString: string[]): boolean => {
    const regex = /[^\s]+/;
    let result = true;
    arrayString.forEach((value) => {
      if (regex.test(value) === false) {
        result = false;
      }
    });

    if (result) {
      return true;
    } else {
      return false;
    }
  };

  const findChecked = (arrCheck: Check[]): Check | undefined => {
    return arrCheck.find((elem) => elem.checked === true);
  };

  const handleSubmit = (
    evt: React.FormEvent<HTMLFormElement>,
    checkBox: Check[] | undefined
  ) => {
    isPreloader(true);
    evt.preventDefault();
    const { discription, price, title } = collectValues(evt);

    const validateInputLength = validetionTextinput([
      discription,
      price,
      title,
    ]);
    if (!validateInputLength) {
      isInvalid(true);
      isPreloader(false);
      isArrValidation("все поля обязательны к заполнению");
      setTimeout(() => isArrValidation(""), 1200);
      return;
    }

    if (!checkBox || !findChecked(checkBox)) {
      isPreloader(false);
      isArrValidation("выберете чекбокс");
      setTimeout(() => isArrValidation(""), 1200);
      return;
    }

    product
      .addProduct({
        token: token,
        discription,
        gender: checkBox,
        price,
        title,
      })
      .then((res) => {
        localStorage.setItem("id-product", res._id);
        formRef.current?.reset();
        navigate("/add-foto-product");
      })
      .catch((err) => {
        isArrValidation(
          `При  добавлении товара на сервере произошла ошибка : "${err.message}"`
        );
        setTimeout(() => isArrValidation(""), 1500);
      })
      .finally(() => isPreloader(false));
  };

  return (
    <div className="form-add-producte">
      <ButtonsNavigation text="назад" page="/" />
      <Form
        formRef={formRef}
        handleSubmit={(evt) => handleSubmit(evt, arrChecked)}
        textButton={"Добавить"}
        textErr={arrValidation}
      >
        <input
          className={`input ${
            invalid
              ? inputValidity.title
                ? "input_state_valid"
                : "input_state_invalid-custom"
              : ""
          }`}
          minLength={1}
          maxLength={50}
          required
          name="title"
          type="text"
          placeholder="название"
          onChange={(evt) => {
            isInputValidity({
              ...inputValidity,
              title: validetionTextinput([evt.target.value]),
            });
          }}
        ></input>
        <input
          className={`input ${
            invalid
              ? inputValidity.price
                ? "input_state_valid"
                : "input_state_invalid-custom"
              : ""
          }`}
          required
          minLength={1}
          maxLength={6}
          name="price"
          type="number"
          placeholder="цена"
          onChange={(evt) => {
            isInputValidity({
              ...inputValidity,
              price: validetionTextinput([evt.target.value]),
            });
          }}
        ></input>
        <textarea
          className={`input ${
            invalid
              ? inputValidity.discription
                ? "input_state_valid"
                : "input_state_invalid-custom"
              : ""
          }`}
          required
          name="discription"
          placeholder="описание"
          rows={3}
          maxLength={1000}
          onChange={(evt) => {
            isInputValidity({
              ...inputValidity,
              discription: validetionTextinput([evt.target.value]),
            });
          }}
        ></textarea>
        {arrChecked ? (
          <CheckBox
            arrChecked={arrChecked}
            isArrChecked={isArrChecked}
            isValid={
              invalid && !arrChecked?.find((elem) => elem.checked === true)
                ? true
                : false
            }
          />
        ) : (
          ""
        )}
      </Form>

      {preloader && <ModulePreloader text="Добавление..." />}
    </div>
  );
}
