import { ReactNode, useEffect } from "react";

type ModulContainerProps = {
  clickOverly?: () => void;
  children: ReactNode;
};

export default function ModulContainer({
  clickOverly,
  children,
}: ModulContainerProps) {
  const onKeydown = (evt: KeyboardEvent) => {
    if (evt.code === "Escape") {
      clickOverly && clickOverly();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeydown);

    return () => document.removeEventListener("keydown", onKeydown);
  }, []);

  return (
    <div
      className={"modul-container"}
      onClick={(evt) => {
        if (evt.target === evt.currentTarget && clickOverly) {
          clickOverly();
        }
      }}
    >
      {children}
    </div>
  );
}
